import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { withRouter } from 'next/router'
import isEmail from 'validator/lib/isEmail'
import { FaApple, FaGithub } from 'react-icons/fa'
import Cookies from 'cookies-js'
import Page from '../../util/Page'
import { CommonHead } from '../../util/CommonHead'
import { apiFetch, parseUrlParams, log } from '../../util'
import { API_TARGET, COOKIE_DOMAIN } from '../../config'
import styles from './login.module.css'
const OutlineLogo = dynamic(() => import('../../icons').then(i => i.OutlineLogo))
const PiBoxLogo = dynamic(() => import('../../icons').then(i => i.PiBoxLogo))

function Login({ router }) {
  const { errMsg } = router.query
  const [error, setError] = useState(errMsg)
  const [needsEmailVerification, setNeedsEmailVerification] = useState(false)

  useEffect(() => {
    const { errMsg } = router.query
    setError(errMsg)
  }, [router.query])

  useEffect(() => {
    if (typeof window === 'undefined') return
    const { redirect, agentProxyAuth } = parseUrlParams()
    if (redirect) {
      log.debug('Setting LOGIN_REDIRECT cookie', { redirect })
      Cookies.set('LOGIN_REDIRECT', redirect, { domain: COOKIE_DOMAIN })
    }
    if (agentProxyAuth) {
      Cookies.set('AGENT_PROXY_AUTH', agentProxyAuth, { domain: COOKIE_DOMAIN })
    }
  }, [])

  const [param = 'login'] = router?.query?.params || []

  if (needsEmailVerification) {
    return (
      <Page className={styles.login + ' translate'}>
        <div className={styles.loginModal}>
          <h1>{param === 'register' ? 'Email Verification' : 'Check Your Email'}</h1>
          <p>
            {param === 'register'
              ? 'Please click the link in your email to verify your account and start using KubeSail!'
              : `We just emailed you a reset password link. Be sure you check your spam folder if you don't see it right away.`}
          </p>
        </div>
      </Page>
    )
  }

  return (
    <Page className={styles.login + ' translate'}>
      <CommonHead url="https://kubesail.com/login" title={'Login'} />
      <div className={styles.loginLeft}>
        <div className={styles.loginLogos}>
          <div className={styles.logo}>
            <OutlineLogo style={{ width: '200px', color: 'black' }} />
          </div>
          <div className={styles.logo}>
            <PiBoxLogo style={{ width: '200px' }} />
          </div>
        </div>
        <p>
          With your KubeSail account, you can control apps on{' '}
          <a href="https://kubesail.com">kubesail.com</a> and manage your orders on{' '}
          <a href="https://pibox.io">pibox.io</a>
        </p>
      </div>
      <div className={styles.loginModal}>
        {param === 'register' ? (
          <h1>Create a KubeSail Account</h1>
        ) : param === 'login' ? (
          <h1>Log In to KubeSail</h1>
        ) : (
          <>
            <h1>Reset Your Password</h1>
            <p>
              Enter the email you used to register. We will send you an email with instructions to
              reset your password.
            </p>
          </>
        )}
        {param === 'register' ? (
          <>
            <p>
              have an account?{' '}
              <Link href="/login" prefetch={false}>
                login
              </Link>
            </p>
          </>
        ) : param === 'login' ? (
          <p>
            need to{' '}
            <Link href="/login/register" prefetch={false}>
              register
            </Link>
            ?
          </p>
        ) : null}
        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.loginContainer}>
          <form
            name="login"
            onSubmit={async e => {
              e.preventDefault()
              const body = {}
              const formData = new URLSearchParams(new FormData(document.forms.login))
              formData.forEach((value, key) => (body[key] = value))
              if (param !== 'forgot' && body.password.length < 8) {
                setError('Your password should be 8 characters or longer')
                return
              }
              if (param === 'register') {
                if (body.password !== body.confirmpassword) {
                  setError('Your passwords do not match')
                  return false
                }
              }
              if (!isEmail(body.email)) {
                setError('Your email is invalid')
                return false
              }
              const { status, json, _headers } = await apiFetch('/' + param, {
                method: 'POST',
                body: { redirect: false, ...body },
              })
              if (status === 202) {
                setNeedsEmailVerification(true)
              } else if (status === 200) {
                const redirect = Cookies.get('LOGIN_REDIRECT')
                log.debug('pages/login: redirecting to', redirect || '/dashboard')
                Cookies.expire('LOGIN_REDIRECT', { domain: COOKIE_DOMAIN })
                window.location = redirect || '/dashboard'
              } else if (status !== 200 && json.message) {
                setError(json.message)
              } else {
                setError('Unknown network error')
              }
            }}
          >
            <label htmlFor="email">
              <div className={styles.inputLabel}>Email</div>
              <input
                type="text"
                name="email"
                id="email"
                autoCapitalize="none"
                autoComplete="username"
              />
            </label>
            {param === 'forgot' ? null : (
              <label htmlFor="password">
                <div className={styles.inputLabel}>Password</div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                />
              </label>
            )}
            {param === 'register' && (
              <label htmlFor="confirmpassword">
                <div className={styles.inputLabel}>Confirm Password</div>
                <input
                  id="confirmpassword"
                  name="confirmpassword"
                  type="password"
                  autoComplete="current-password"
                />
              </label>
            )}
            <input type="submit" value={param === 'register' ? 'Create Account' : 'Continue'} />
            {param === 'login' && (
              <p>
                forgot{' '}
                <Link href="/login/forgot" prefetch={false}>
                  password
                </Link>
                ?
              </p>
            )}
          </form>
        </div>
        {param === 'register' && (
          <p>
            By creating an account, you agree to our <Link href="/terms">terms</Link> and{' '}
            <Link href="/privacy">privacy policy</Link>
          </p>
        )}
        <h2>OR</h2>
        <form method="POST" action={`${API_TARGET}/login/apple`} className="right">
          <button className={styles.socialAuth}>
            <FaApple className={styles.socialAuthIcon} />
            Sign In with Apple
          </button>
        </form>
        <form method="POST" action={`${API_TARGET}/login/github`} className="right">
          <button className={styles.socialAuth}>
            <FaGithub className={styles.socialAuthIcon} />
            Sign In with GitHub
          </button>
        </form>
        {/* <form method="POST" action={`${API_TARGET}/login/google`} className="right">
          <button className={styles.socialAuth}>
            {GoogleColorIcon({ className: styles.socialAuthIcon })}
            Sign In with Google
          </button>
        </form> */}
      </div>
    </Page>
  )
}

export default withRouter(Login)
